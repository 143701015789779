var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"290","content-class":"gift-dialog","overlay-opacity":"0.9"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.beforeClicked)?_c('v-card',{staticClass:"d-flex flex-column justify-center align-center pa-3 white--text",attrs:{"color":"transparent"},on:{"click":_vm.claim}},[(_vm.api.isLoading)?_c('v-progress-circular',{attrs:{"size":"100","width":"10","indeterminate":"","color":"white"}}):_c('v-img',{staticClass:"zoom-in-out-box",attrs:{"width":"200","contain":"","src":require('@/assets/explore/CompletionGift1.png')}}),_vm._v(" "+_vm._s(_vm.$t("action.clickToClaim"))+" ")],1):(_vm.afterClicked)?_c('RewardDialog',{attrs:{"callbackClose":_vm.callbackClose,"showDialog":true,"rewards":[
      {
        Item: {
          images: { icon: { url: require('@/assets/explore/coin.png') } },
        },
        quantity: _vm.quantity,
      },
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }